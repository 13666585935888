import router from "./index"
import store from "@/store/index"
import { defineAsyncComponent } from "vue"

const myMenus = {
    MyGroups: {zhcn: '我的用户组', en: 'My Groups'},
    UserCenter: {zhcn: '用户中心', en: 'User Center'}
}

function createRouteParam(menu) {
    console.log('route menu', menu.component)
    const route = {
        path: menu.path,
        name: menu.menuName,
        component: () => defineAsyncComponent(() => {
            return new Promise((resolve, reject) => {
                resolve(import('../views/device/DeviceGroups.vue'))
            })

        })
    }
    return route
}

export function generaetRoutes() {
    // console.log('get menus', store)
    let menus = store.state.user.userMenus
    menus.forEach(element => {
        let route = createRouteParam(element)
        console.log('add route', route)
        router.addRoute('ClientIndex', route)
    });
    console.log('route add', router.getRoutes())
}
