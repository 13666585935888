<template>

<div>
    <el-dialog v-model="data.visiable" :title="data.title" width="352px" center align-center>
    <div class="slide-back" :style="{'background-image': 'url(' + data.backimg +')'}">
        <div class="slider-block" :style="{ 'top': data.y + 'px'  ,'left': block_x + 'px'}">
            <el-image :src="data.slideImg" fit="fill" />
        </div>
    </div>
    <template #footer>
      <div class="slide-block">
        <el-slider v-model="data.x" size="large"
        :show-tooltip="false"
        @change="doLogin"/>
      </div>
    </template>
    </el-dialog>
</div>
</template>

<script setup>
import { computed, defineProps, ref,onMounted, reactive } from "vue";
import { ElDialog,ElImage,ElSlider } from "element-plus";
import { getPageDesc } from '@/utils/storeUtils'
import serviceapi from '@/api/api'

onMounted(() => {
    refreshImg()
})

const imgW = ref(320)
const data = reactive({
    visiable: true,
    y: 0,
    x: 0,
    backimg: '',
    slideImg: '',
    title: '拖动滑块验证'
})
const x = ref(0)
const token = ref('')


const props = defineProps({
    loginSuccess: Function
})

const getVerifyImg = () => {
    console.log('get img')
    return new Promise((reslove, reject) => {
        serviceapi.post('/wrapi/login/verifyImg/getImage').then(
            function (responese) {
                let resp = responese.data
                data.backimg = 'data:image/png;base64,' + resp.bigImg
                data.slideImg = 'data:image/png;base64,' + resp.smallImg
                data.y = resp.y
                token.value = resp.token
            }
        )}
    )
}

const refreshImg = () => {
    getVerifyImg().then(
        data.x = 0
    )
}

const verifyImg = () => {
  let postdata = {
    'token': token.value,
    'x': block_x.value
  }
  return new Promise((reslove, reject) => {
    serviceapi.post('/wrapi/login/verifyImg/verify', postdata).then(
      function (resp) {
        console.log('verifyImg', resp)
        if (resp.code != 200) {
          reject()
        }
        reslove(resp)
    }, error => {
        console.log('verifyImag faild')
        reject()
    })
  })
}

const loginAgent = () => {
    let jsondata = {
        'token': token.value,
        'x': block_x.value
    }
    return new Promise((reslove, reject) => {
        serviceapi.post('/wrapi/v1/device/agent/login', jsondata).then(
        function (resp) {
            console.log('success loginAgent')
            reslove(resp)
        })
    })
}

const doLogin = () => {
    verifyImg().then(
        loginAgent().then(
            props.loginSuccess()
        )
    ).catch(() => {
        refreshImg()
    })
}


const moveSlider = (x) => {
    console.log('move slider', x)
}
const block_x = computed({
       get() {
        let left_x = imgW.value * data.x / 100
        return left_x
       },
       set(val) {
         return val
       }
    })

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slider-block {
    position: relative;
}
.slide-back {
    width: 320px;
    height: 160px;
}
</style>
