import { getUserInfo, getMenus } from '@/api/userapi'
import { getToken, setToken, removeToken } from '@/utils/auth'
import {generaetRoutes} from '@/router/dynamicroute'

const user = {
  state: {
    token: getToken(),
    name: '',
    avatar: '',
    roles: [],
        permissions: [],
    userMenus: []
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
      },
      GENERATE_MENUS: (state, permissions) => {
          console.log('generate menus')
          let resMenus = permissions.filter(s => s.menuType == 'M')
          state.userMenus = resMenus

    }
  },

  actions: {
    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
          getUserInfo().then(res => {
              console.log('user info', res)
              const user = res.data
              commit('SET_NAME', user.name)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
      },
      GetMenus({ commit, state }) {
        return new Promise((resolve, reject) => {
            getMenus().then(res => {
                const menus = res.data
                commit('SET_PERMISSIONS', menus)
                commit('GENERATE_MENUS', menus)
                console.log('get permission success', state.permissions)
                generaetRoutes()
                resolve(res)
            }).catch(error => {
          reject(error)
        })
      })
  }
    },

}

export default user
